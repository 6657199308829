import React, { useEffect, useState } from 'react';
import { marked } from 'marked';

import { BotTextContent } from './styled';

const TypewriterMessage = ({ text = '', showHTML }) => {
  const [displayedText, setDisplayedText] = useState('');
  // const indexRef = useRef(0);

  useEffect(() => {
    if (showHTML) {
      const bodyContent = marked(text);

      setDisplayedText(bodyContent);
    } else {
      setDisplayedText(text);
    }
    // } else {
    //   // If no <body> tag found, fallback to setting the text directly
    //   setDisplayedText(text);
    // }
  }, [text]);
  return (
    <BotTextContent className="d-flex">
      {showHTML ? (
        // eslint-disable-next-line react/no-danger
        <div className="mb-0" dangerouslySetInnerHTML={{ __html: displayedText }}></div>
      ) : (
        <p className="mb-0">{displayedText}</p>
      )}
    </BotTextContent>
  );
};

export default TypewriterMessage;
