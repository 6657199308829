import styled from 'styled-components';
import media from 'themes/media';

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 92px 0 0 260px;
  transition: all 0.3s ease-in-out;
  ${media.extraLarge`
    padding: 92px 0 0 260px;
  `};
  @media (max-width: 767px) {
    padding: 92px 0 0 0;
  }
  &.sidebar-close {
    padding-left: 0px;
    ${media.medium`
    padding-left: 90px
    `}
  }
  &.searchbarClose {
    padding-top: 70px;
    /* ${media.medium`
    padding-left: 90px
    `} */
  }
  &.searchbarOpen {
    padding-top: 160px;
  }
  &.auth-layout {
    padding: 140px 0px 60px;
  }
  &.public-layout {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0;
  }
  .layout-v2 & {
    padding: 0;
    &.auth-layout {
      flex: 1 1 0%;
    }
    ${media.medium`
      margin-left: 270px;
      &.auth-layout {
        padding: 92px 0 0;
      }
    `};
    ${media.large`
      margin-left: 361px;
    `};
  }
`;

export default MainContent;
