import { GPTSuggestionContainer } from 'containers/ClientHomePage/styles';

export const instructionData = [
  {
    header: 'Setup:',
    headerClass: 'my-1',
    instructionItems: [
      'This AI Co-Pilot is trained on our Product Backlog defined in Jira.',
      'This Co-Pilot has analysed our existing team members on various parameters.',
      'The Talents are recommended from our Talent Marketplace, available for anyone to join.',
    ],
  },
  {
    header: 'What it can do:',
    headerClass: 'my-1',
    instructionItems: [
      'Identify skills and resourcing needs as per our upcoming backlog',
      'Identify gaps in existing team',
      'Identify the ideal candidate profile to be hired',
      'Recommend best suitable candidates',
      'Provide comparative summary of recommended candidates',
      'Schedule interviews in your calendar',
    ],
  },
  {
    header: 'What it will do soon:',
    headerClass: 'my-1',
    instructionItems: [
      'Identify performance issues and resolutions',
      'Identify learning needs of team members',
      'Restructure existing team across multiple projects',
      'Define OKRs for team members',
      'Define Learning & Development plans',
      'Help execute 1:1 with team members',
      'Help with Line Management',
      'Execute custom workflows',
      'Integrate with various HR tools',
    ],
  },
];

export const feedbackSearchSuggestions = [
  'What skills are needed to complete the user stories, and do we have these skills in the team?',
  'My technical debt is increasing. Can you help me with some solution?',
  'I want to hire a talent based on my backlog',
  'What do you think about our backlog?',
  'Suggest me a talent considering my JIRA backlog?',
  'What challenges do you see in my backlog?',
  'Can you review my JIRA backlog and suggest gaps?',
  'Based on my backlog how many people do I need in my team?',
  'Based on the sprint velocity where do we lack?',
];

export const renderFeedbackSearchSuggestions = ({ onClick, className, suggestions = [] }) => (
  <GPTSuggestionContainer className={`client-header ${className || ''}`} onClick={onClick}>
    {suggestions.map((suggestion, index) => (
      <div key={index} className="suggestion-item" data-testid={`suggestion-item-${index}`}>
        {suggestion}
      </div>
    ))}
  </GPTSuggestionContainer>
);
