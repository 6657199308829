/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { H5, P, H3, Badge, Button, SVGIcon, A } from 'components';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import {
  teamIcon,
  certificationsIcon,
  industryIcon,
  diamondIcon,
  userProfileIcon,
  timeXZone,
  starTickIcon,
  teamPreferenceArray,
  CLIENT,
  plusIcon,
  circleTickIcon,
  bookmarkedIcon,
  bookmarkIcon,
  talentInterviewedIcon,
  talentInterviewIcon,
  addedToCompareIcon,
  compareTalentIcon,
} from 'containers/App/constants';
import { propTypes } from 'containers/proptypes';
import ToolTip from 'components/ToolTip';
import LazyImage from 'components/LazyImage';
import profileContainerMessages from 'containers/MyProfilePage/messages';
import containerMessage from 'containers/messages';
import { UserProfile, UserProfileImg, PreferencesList } from 'containers/MyProfilePage/styles';
import { getArrayLabels, getTimezoneOffest } from 'containers/MyProfilePage/components/utils';
import { Cell, PieChart, Pie } from 'recharts';
import UnControlledTooltip from 'components/UnControlledTooltip';
import { TalentCard } from './styles';
import { COLORS } from './constants';
import { redirectTo } from 'containers/App/utils';
import { primaryNew } from 'themes/variables';
import { LoaderLines } from 'components/SkeletonLoader';
import { getUserRegisterType, userExists } from 'utils/Helper';
import { useDispatch, useSelector } from 'react-redux';
import { updateTalentComparison } from 'containers/CompareTalentSticky/reducer';
import { colorMapper, getColorVariant, getScoreBreakdownContent } from './utils';

export const TalentCardComponent = ({
  talentData,
  hideInterviewBtn,
  project = {},
  clientDetails,
  SubscriptionModalRef,
  isPublic = false,
  setSaveToAList,
  openNewTabForInterview,
  disableSaveToList = false,
  disableCompareTalent = false,
  interviewAction = null,
}) => {
  const isSubscribed = clientDetails?.subscribed;
  const recommendedId = get(talentData, 'recommendedId', null);
  const compareTalentsList = useSelector((state) => state.compareTalents?.compareTalents);
  const dispatch = useDispatch();

  const hireTalent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isSubscribed && !interviewAction) {
      return SubscriptionModalRef.current.handleSubscriptionPlanOpenModal();
    }
    if (interviewAction) {
      interviewAction?.();
      return;
    }
    const projectTalentId = get(project, 'projectId', '');
    const projectName = get(project, 'projectName', '');
    const projectSummary = get(project, 'projectDescription', '');
    const jobBriefId = get(project, 'briefID', '');
    const jobBriefName = get(project, 'briefName', '');

    redirectTo(null, `${CLIENT}/schedule-interview`, {
      data: {
        ...talentData,
        talentId: get(talentData, 'talentId', get(talentData, '_id', get(talentData, 'talentUserId', ''))),
      },
      hireData: { projectTalentId, projectName, projectSummary, recommendedId, jobBriefId, jobBriefName },
    });
  };

  const id = get(talentData, '_id', '');
  const profileId = get(talentData, 'profileId', '');

  const matchData = [
    {
      name: 'Filled',
      value: parseFloat(get(talentData, 'match', '0').slice(0, -1)),
    },
    {
      name: 'Not-Filled',
      value: 100 - parseFloat(get(talentData, 'match', '0').slice(0, -1)),
    },
  ];

  const parsedMatchValue = parseFloat(get(talentData, 'match', '0').slice(0, -1));
  // eslint-disable-next-line no-nested-ternary
  const variant = getColorVariant(parsedMatchValue);
  const percentageFillColor = variant in colorMapper ? `rgb(${colorMapper[variant]})` : `rgba(${primaryNew}, 0.2)`;

  const profileUrl = recommendedId !== null ? `/talent/${profileId}?recommendedId=${recommendedId}` : `/talent/${profileId}`;

  const registerType = getUserRegisterType();

  const handleCompareTalentClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(updateTalentComparison(talentData));
  };

  const InterviewButtonOrLink = () => {
    // Common project and talent data handling here
    const projectTalentId = get(project, 'projectId', '');
    const projectName = get(project, 'projectName', '');
    const projectSummary = get(project, 'projectDescription', '');
    const jobBriefId = get(project, 'briefID', '');
    const jobBriefName = get(project, 'briefName', '');

    const interviewData = {
      data: {
        timeZone: talentData.timeZone,
        _id: talentData._id,
        name: talentData.name,
        lastName: talentData.lastName,
        talentId: get(talentData, 'talentId', get(talentData, '_id', get(talentData, 'talentUserId', ''))),
        verifiedProfile: talentData.verifiedProfile,
        profilePicture: talentData.profilePicture,
        firstName: talentData.firstName,
        city: talentData.city,
        country: talentData.country,
        yearsOfExperience: talentData.yearsOfExperience,
        primaryRole: talentData.primaryRole,
      },
      hireData: { projectTalentId, projectName, projectSummary, recommendedId, jobBriefId, jobBriefName },
    };

    const encodedData = encodeURIComponent(JSON.stringify(interviewData));

    return openNewTabForInterview && !interviewAction ? (
      <Link
        to={{
          pathname: `${CLIENT}/schedule-interview?data=${encodedData}`,
          state: interviewData, // Pass the interview data as state
        }}
        target="_blank"
        onClick={(e) => {
          e.stopPropagation();
        }}
        data-testid={`interview-${id}`}
      >
        <ToolTip
          tooltipId={`interview_${talentData.profileId}`}
          content={'Interview'}
          otherIcon={talentInterviewIcon}
          type={'other'}
          placement={'top'}
        />
      </Link>
    ) : (
      <Button
        className="btn-link d-flex align-items-center me-2"
        onClick={(e) => hireTalent(e)} // Call hireTalent when clicked
      >
        <ToolTip
          tooltipId={`interview_${talentData.profileId}`}
          content={'Interview'}
          otherIcon={talentInterviewIcon}
          type={'other'}
          placement={'top'}
        />
      </Button>
    );
  };

  return (
    <>
      <TalentCard
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!isSubscribed && userExists()) {
            return SubscriptionModalRef.current.handleSubscriptionPlanOpenModal();
          }
          window.open(profileUrl, '_blank', 'noopener,noreferrer');
        }}
        key={id}
        className="card-sm flex-1 d-flex flex-column"
      >
        {/* <TalentCard to={profileUrl} target="_blank" key={id} className="card-sm flex-1 d-flex flex-column"> */}
        <div className="d-flex flex-0 justify-content-between align-items-center mb-3">
          <UserProfileImg>
            <LazyImage
              src={get(talentData, 'profilePicture', '')}
              alt={`${id} user-profile`}
              placeholderSrc={userProfileIcon}
              className="user-logo"
              wrapperClassName="user-logo"
            />
          </UserProfileImg>
          <div className="d-flex">
            {!hideInterviewBtn && <InterviewButtonOrLink />}
            {registerType === 'client' && !disableCompareTalent && (
              <>
                <Button
                  className="btn-link d-flex align-items-center me-2"
                  onClick={handleCompareTalentClick}
                  data-testid={`compare-${id}`}
                >
                  <ToolTip
                    tooltipId={`addToCompare_${talentData.profileId}`}
                    content={compareTalentsList[talentData.talentUserId] ? 'Added to compare' : 'Compare'}
                    otherIcon={compareTalentsList[talentData.talentUserId] ? addedToCompareIcon : compareTalentIcon}
                    type={'other'}
                    placement={'top'}
                  />
                </Button>

                {!disableSaveToList && (
                  <Button
                    className="btn-link d-flex align-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSaveToAList(true);
                    }}
                    data-testid={`save-to-alist-btn_${talentData._id}`}
                  >
                    <ToolTip
                      tooltipId={talentData?.isAddedToList ? `bookmarked_${talentData.profileId}` : `bookmark_${talentData.profileId}`}
                      content={talentData?.isAddedToList ? 'Added to a list' : 'Save to a list'}
                      otherIcon={talentData?.isAddedToList ? bookmarkedIcon : bookmarkIcon}
                      type={'other'}
                      placement={'top'}
                    />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        <div className="d-flex flex-0 mt-0">
          <div className="d-flex justify-content-between w-100 flex-column">
            <div className="d-flex justify-content-between w-100 align-items-start">
              <div className={`d-flex align-items-center ${get(talentData, 'match', '') ? 'col-md-6 col-lg-8' : ''}`}>
                {isPublic || isSubscribed ? (
                  <H3>
                    {get(talentData, 'firstName')} {get(talentData, 'lastName')}.
                  </H3>
                ) : (
                  <LoaderLines className="m-0 p-0" count={1} height={25} width={150} />
                )}
                {get(talentData, 'verifiedProfile', false) && (
                  <ToolTip
                    wrapperClass="d-inline-flex ms-1 align-text-top"
                    type="other"
                    otherIcon={starTickIcon}
                    placement="right"
                    content={
                      <ul className="m-0 ps-2">
                        <li className="text-start">{profileContainerMessages.interviewedForSkillsAndExpertise.defaultMessage}</li>
                        <li className="text-start">{profileContainerMessages.checkedForEducationAndExperiences.defaultMessage}</li>
                        <li className="text-start">{profileContainerMessages.verifiedPhotoIDandAddress.defaultMessage}</li>
                      </ul>
                    }
                    tooltipId={`tooltip${id}_talent_card`}
                  />
                )}
              </div>

              {get(talentData, 'match', '') && (
                <>
                  <div className="d-flex align-items-center" id={`TooltipTalentCardComponentMatch_${talentData._id}`}>
                    <PieChart
                      width={30}
                      height={30}
                      // onMouseEnter={this.onPieEnter}
                    >
                      <Pie data={matchData} cx={10} cy={10} innerRadius={5} outerRadius={10} fill="#8884d8" paddingAngle={0}>
                        {matchData.map((entry, index) => {
                          const fill = entry.name === 'Filled' ? percentageFillColor : `rgba(${primaryNew}, 0.2)`;
                          return <Cell fill={fill} />;
                        })}
                      </Pie>
                    </PieChart>
                    <P
                      className="p14 m-0 p-0"
                      style={{
                        color: percentageFillColor,
                      }}
                    >
                      {get(talentData, 'match', '')}
                    </P>
                  </div>
                  <UnControlledTooltip
                    targetId={`TooltipTalentCardComponentMatch_${talentData._id}`}
                    content={
                      talentData.scoreBreakdown
                        ? getScoreBreakdownContent(talentData.scoreBreakdown)
                        : `${get(talentData, 'match', '')} Match`
                    }
                    placement="top"
                    className="tooltip-white-bg"
                  />
                </>
              )}
            </div>

            <P className="p14 mb-0" opacityVal="0.5">
              {get(talentData, 'city')}, {get(talentData, 'country')} ({getTimezoneOffest(timeXZone, 'name', get(talentData, 'timeZone'))})
            </P>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-sm-between">
          <div>
            <P className="p14 mb-2" opacityVal="0.5">
              {get(talentData, 'yearsOfExperience')}
            </P>
            <H5>{get(talentData, 'primaryRole')}</H5>
          </div>
        </div>
        <hr />
        {get(talentData, 'skills') && get(talentData, 'skills', []).length >= 1 ? (
          <>
            <div className="d-flex flex-wrap flex-0">
              {get(talentData, 'skills', [])
                .slice(0, 3)
                .map((subItem) => (
                  <Badge key={subItem.name} className="primary badge-sm me-2 mb-2">
                    {subItem.name}
                  </Badge>
                ))}
              {get(talentData, 'skills', []).length > 3 && (
                <Badge className="primary badge-sm me-2 mb-2">+{get(talentData, 'skills', []).length - 3} Skills</Badge>
              )}
            </div>
            <hr />
          </>
        ) : (
          ''
        )}
        <PreferencesList className="d-flex flex-column flex-1">
          {get(talentData, 'certificateDetails') && get(talentData, 'certificateDetails', []).length >= 1 ? (
            <li>
              <SVG src={certificationsIcon} />
              <P className="p16 mb-0 text-truncate d-block" opacityVal="0.5">
                {get(talentData, 'certificateDetails', [])
                  .map((obj) => obj.name)
                  .map((i) => i)
                  .join(', ')}
              </P>
            </li>
          ) : (
            ''
          )}
          {get(talentData, 'industries') && get(talentData, 'industries', []).length >= 1 ? (
            <li>
              <SVG src={industryIcon} />
              <P className="p16 mb-0 text-truncate d-block" opacityVal="0.5">
                {get(talentData, 'industries', [])
                  .map((i) => i)
                  .join(', ')}
              </P>
            </li>
          ) : (
            ''
          )}
          {get(talentData, 'companyCultures') && get(talentData, 'companyCultures', []).length >= 1 ? (
            <li>
              <SVG src={diamondIcon} />
              <P className="p16 mb-0 text-truncate d-block" opacityVal="0.5">
                {get(talentData, 'companyCultures', [])
                  .map((i) => i)
                  .join(', ')}
              </P>
            </li>
          ) : (
            ''
          )}
          {get(talentData, 'teamPreference') && get(talentData, 'teamPreference', []).length >= 1 ? (
            <li>
              <SVG src={teamIcon} />
              <P className="p16 mb-0 text-truncate d-block" opacityVal="0.5">
                {getArrayLabels(get(talentData, 'teamPreference', []), [
                  {
                    id: 1,
                    label: 'Individuals',
                    isChecked: false,
                    value: 'individuals',
                  },
                  ...teamPreferenceArray,
                ])
                  .map((i) => i)
                  .join(', ')}
              </P>
            </li>
          ) : (
            ''
          )}
        </PreferencesList>
        <hr />
        <div className="flex-0">
          <P className="p14 mb-2" opacityVal="0.5">
            Formerly at
          </P>
          <P className="p16 mb-0">{get(talentData, 'formerEmployer', [])}</P>
        </div>
        {talentData?.justification && interviewAction && (
          <>
            <hr />
            <div className="flex-0">
              <P className="p16 mb-0">{get(talentData, 'justification', '')}</P>
            </div>
          </>
        )}
      </TalentCard>
    </>
  );
};

TalentCardComponent.propTypes = propTypes;

export default TalentCardComponent;
