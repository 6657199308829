import Content from 'components/Content';
import styled from 'styled-components';
import { growAnimation } from '../GPTSearchBot/styled';
import { primaryDarkNew } from 'themes/variables';

export const FeedbackBotContainer = styled(Content)`
  color: rgb(${primaryDarkNew});
  .info-btn {
    position: relative;
    svg {
      position: absolute;
      bottom: -4px;
    }
  }
`;

export const FeedbackBotChatContainer = styled.div`
  border-radius: 20px;
  height: 80vh;
  animation: ${growAnimation} 0.5s ease-in-out;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #04004e;
  .bg-bot {
    background: #04004e08;
  }
  .bg-user {
    background: #1833cc1a;
  }
  .search-input {
    border: 1px solid #04004e1a;
    background: #1833cc08;
    box-shadow: none;
    border-radius: 30px;
  }
`;

export const InstructionContainer = styled.div`
  .instruction-item {
    opacity: 0.8;
  }
  padding: 10px;
  background: #04004e08;
  position: relative;
  .close-icon {
    border: none !important;
    background-color: transparent;
    position: absolute;
    right: 10px;
  }
`;
