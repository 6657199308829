import Content from 'components/Content';
import styled, { keyframes } from 'styled-components';

export const growAnimation = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: 70vh;
  }
`;

export const GPTContainer = styled(Content)`
  &.gpt-container {
    border-radius: 20px;
    height: 80vh;
    animation: ${growAnimation} 0.5s ease-in-out;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #04004e;
    .bg-bot {
      background: #04004e08;
    }
    .bg-user {
      background: #1833cc1a;
    }
    .search-input {
      border: 1px solid #04004e1a;
      background: #1833cc08;
      box-shadow: none;
      border-radius: 30px;
    }
  }
`;

export const BotTextContent = styled.div``;
